import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Content from './Content'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Wrapper = styled.footer`
  color: ${props => props.theme.colors.primary};
  padding-top: 80px;
  padding-bottom: 20px;
  background-color: ${props => props.theme.colors.primaryLight};

  @media (max-width: 375px) {
    padding-top: 35px;
    padding-bottom: 0;
  }

  a:hover {
    font-weight: 500;
  }

  a.is-active {
    font-weight: 500;
  }

  ${props =>
    props.plain &&
    css`
      background-color: white;
    `}
`

const Cols = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`

const Col = styled.div`
  width: 200px;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    width: 50%;
    margin-bottom: 30px;
  }

  @media (max-width: 375px) {
    width: 100%;
  }

  ${props =>
    props.center &&
    css`
      width: 250px;
    `}

  ${props =>
    props.last &&
    css`
      font-size: 12px;
      line-height: 2.25;

      @media (max-width: 768px) {
        width: 100%;
        line-height: 1.8;
      }
    `}
`

const StyledLink = styled(Link)`
  padding: 4px;
  margin: -4px;
  display: inline-block;
`

const Footer = ({ plain }) => {
  const intl = useIntl()

  return (
    <Wrapper plain={plain}>
      <Content>
        <Cols>
          <Col>
            Oberhuber Jenal
            <br />
            Rechtsanwälte AG
            <br />
            Wuhrstrasse 14
            <br />
            LI-9490 Vaduz
          </Col>
          <Col center>
            {intl.formatMessage({ id: 'contact.phone' })}{' '}
            <a href="tel:+4232377080">+423 237 70 80</a>
            <br />
            <a href="mailto:info@oberhuberjenal.li">info@oberhuberjenal.li</a>
            <br />
            <a href="https://www.oberhuberjenal.li">www.oberhuberjenal.li</a>
          </Col>
          <Col last>
            <StyledLink
              to={
                intl.locale === 'de' ? '/de/datenschutz' : '/en/privacy-policy'
              }
              activeClassName="is-active"
            >
              {intl.formatMessage({ id: 'nav.privacy' })}
            </StyledLink>
            <br />
            <StyledLink
              to={intl.locale === 'de' ? '/de/impressum' : '/en/imprint'}
              activeClassName="is-active"
            >
              {intl.formatMessage({ id: 'nav.imprint' })}
            </StyledLink>
          </Col>
        </Cols>
      </Content>
    </Wrapper>
  )
}

export default Footer
