import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { useIntlPlugin } from '../../plugins/gatsby-plugin-intl'
import theme from '../theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -8px;
  margin-right: -8px;

  span:last-of-type {
    display: none;
  }
`

const LanguageLink = styled(Link)`
  display: block;
  padding: 8px;
  text-transform: uppercase;
  color: ${theme.colors.primary};

  ${props =>
    props.isActive &&
    css`
      font-weight: bold;
    `}
`

const Spacer = styled.span`
  padding: 0;
  display: block;
`

function LanguageSwitch() {
  const intlPlugin = useIntlPlugin()

  return (
    <Wrapper>
      {intlPlugin.languages.map(language => {
        return (
          <div key={language}>
            <LanguageLink
              to={`/${language}/`}
              isActive={intlPlugin.language === language}
            >
              {language}
            </LanguageLink>{' '}
            <Spacer>|</Spacer>
          </div>
        )
      })}
    </Wrapper>
  )
}

export default LanguageSwitch
