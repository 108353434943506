import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Wrapper = styled.nav`
  padding-right: 20px;

  @media (max-width: 1024px) {
    display: none;
  }
`

const List = styled.ul`
  margin: -8px -6px;
  padding: 0;
  list-style: none;
  display: flex;
`

const Item = styled.li`
  height: 31px;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  padding: 8px 6px;
  position: relative;

  &::after {
    content: attr(data-text);
    display: block;
    font-weight: 500;
    visibility: hidden;
    overflow: hidden;
    height: 0px;
    line-height: 0;
    padding: 8px 6px;
    transform: translateY(-100%);
  }

  &:hover,
  &.is-active {
    font-weight: 500;
  }
`

const Nav = () => {
  const intl = useIntl()

  return (
    <Wrapper>
      <List>
        <Item>
          <StyledLink
            to={intl.locale === 'de' ? '/de/kanzlei' : '/en/firm'}
            data-text={intl.formatMessage({ id: 'nav.firm' })}
            activeClassName="is-active"
          >
            {intl.formatMessage({ id: 'nav.firm' })}
          </StyledLink>
        </Item>
        <Item>
          <StyledLink
            to={
              intl.locale === 'de' ? '/de/taetigkeitsbereiche' : '/en/services'
            }
            data-text={intl.formatMessage({ id: 'nav.services' })}
            activeClassName="is-active"
          >
            {intl.formatMessage({ id: 'nav.services' })}
          </StyledLink>
        </Item>
        {/*
        <Item>
          <StyledLink
            to="/blog"
            data-text="Blog"
            activeClassName="is-active"
            partiallyActive={true}
          >
            Blog
          </StyledLink>
        </Item>
        */}
        <Item>
          <StyledLink
            to={intl.locale === 'de' ? '/de/anwaelte' : '/en/lawyers'}
            data-text={intl.formatMessage({ id: 'nav.lawyers' })}
            activeClassName="is-active"
          >
            {intl.formatMessage({ id: 'nav.lawyers' })}
          </StyledLink>
        </Item>
        <Item>
          <StyledLink
            to={intl.locale === 'de' ? '/de/kontakt' : '/en/contact'}
            data-text={intl.formatMessage({ id: 'nav.contact' })}
            activeClassName="is-active"
          >
            {intl.formatMessage({ id: 'nav.contact' })}
          </StyledLink>
        </Item>
      </List>
    </Wrapper>
  )
}

export default Nav
