import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 100%;
  width: 1350px; /* 1200 */
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-left: 75px;
  padding-right: 75px;

  @media (max-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: 580px) {
    padding-left: 35px;
    padding-right: 35px;
  }

  @media (max-width: 375px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

const Content = ({ children }) => <Wrapper>{children}</Wrapper>

export default Content
