import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { useIntl } from '../../plugins/gatsby-plugin-intl'
import LanguageSwitch from './LanguageSwitch'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.primary};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  visibility: hidden;

  ${props =>
    props.isActive &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

const Meta = styled.div`
  font-size: 1.5rem;
  margin-top: 4rem;
  margin-top: 6rem;

  @media (max-width: 375px) {
    font-size: 1.25rem;
  }
`

const Language = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: #fff !important;
    padding: 0 8px;
  }
`

const Nav = styled.nav`
  font-size: 2rem;
  line-height: 1.7;

  @media (max-width: 375px) {
    font-size: 1.75rem;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li``

const StyledLink = styled(Link)`
  display: block;

  &:hover {
    color: #fff;
  }

  &.is-active {
    font-weight: 500;
  }
`

const Overlay = ({ isActive }) => {
  const intl = useIntl()

  return (
    <Wrapper isActive={isActive}>
      <Content>
        <div>
          <Nav>
            <List>
              <Item>
                <StyledLink
                  to={intl.locale === 'de' ? '/de/kanzlei' : '/en/firm'}
                  title={intl.formatMessage({ id: 'nav.firm' })}
                  activeClassName="is-active"
                >
                  {intl.formatMessage({ id: 'nav.firm' })}
                </StyledLink>
              </Item>
              <Item>
                <StyledLink
                  to={
                    intl.locale === 'de'
                      ? '/de/taetigkeitsbereiche'
                      : '/en/services'
                  }
                  title={intl.formatMessage({ id: 'nav.services' })}
                  activeClassName="is-active"
                >
                  {intl.formatMessage({ id: 'nav.services' })}
                </StyledLink>
              </Item>
              {/*
              <Item>
                <StyledLink
                  to="/blog"
                  title="Blog"
                  activeClassName="is-active"
                  partiallyActive={true}
                >
                  Blog
                </StyledLink>
              </Item>
              */}
              <Item>
                <StyledLink
                  to={intl.locale === 'de' ? '/de/anwaelte' : '/en/lawyers'}
                  title={intl.formatMessage({ id: 'nav.lawyers' })}
                  activeClassName="is-active"
                >
                  {intl.formatMessage({ id: 'nav.lawyers' })}
                </StyledLink>
              </Item>
              <Item>
                <StyledLink
                  to={intl.locale === 'de' ? '/de/kontakt' : '/en/contact'}
                  title={intl.formatMessage({ id: 'nav.contact' })}
                  activeClassName="is-active"
                >
                  {intl.formatMessage({ id: 'nav.contact' })}
                </StyledLink>
              </Item>
            </List>
          </Nav>
          <Meta>
            <Language>
              <LanguageSwitch />
            </Language>
            <StyledLink
              to={
                intl.locale === 'de' ? '/de/datenschutz' : '/en/privacy-policy'
              }
              title={intl.formatMessage({ id: 'nav.privacy' })}
            >
              {intl.formatMessage({ id: 'nav.privacy' })}
            </StyledLink>
            <StyledLink
              to={intl.locale === 'de' ? '/de/impressum' : '/en/imprint'}
              title={intl.formatMessage({ id: 'nav.imprint' })}
            >
              {intl.formatMessage({ id: 'nav.imprint' })}
            </StyledLink>
          </Meta>
        </div>
      </Content>
    </Wrapper>
  )
}

export default Overlay
