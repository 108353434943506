import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import Content from './Content'
import Logo from './Logo'
import Nav from './Nav'
import NavButton from './NavButton'
import LanguageSwitch from './LanguageSwitch'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Wrapper = styled.header``

const Main = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 248px;
  padding-bottom: 46px;

  @media (min-width: 375px) and (max-width: 540px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  @media (max-width: 1024px) {
    height: 240px;
  }

  @media (max-width: 460px) {
    height: 210px;
  }

  @media (max-width: 420px) {
    height: 190px;
  }

  @media (max-width: 375px) {
    height: 190px;
  }
`

const leftRightStyles = css`
  flex: auto;
  position: relative;

  @media (max-width: 1024px) {
    width: 50%;
  }
`

const Left = styled.div`
  ${leftRightStyles}
  width: 62.5%;
  position: relative;
`

const Right = styled.div`
  ${leftRightStyles}
  width: 37.5%;

  @media (max-width: 1024px) {
    flex-basis: 50%;
  }

  @media (max-width: 375px) {
    width: 66.666666%;
  }
`

const Language = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;

  @media (max-width: 1024px) {
    display: none;
  }
`

const Header = ({ onOverlayToggle, isOverlayActive }) => {
  const intl = useIntl()

  return (
    <Wrapper id="header">
      <Content>
        <Main>
          <Language>
            <LanguageSwitch />
          </Language>
          <Left>
            <Nav />
            <NavButton isActive={isOverlayActive} onClick={onOverlayToggle} />
          </Left>
          <Right>
            <Link to={`/${intl.locale}/`}>
              <Logo />
            </Link>
          </Right>
        </Main>
      </Content>
    </Wrapper>
  )
}

export default Header
