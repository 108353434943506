import React from 'react'
import styled, { css } from 'styled-components'

const Button = styled.button`
  height: 24px;
  width: 48px;
  background: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 101;
  cursor: pointer;
  outline: none;
  display: none;
  border: 0;
  position: absolute;
  bottom: 50px;
  left: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    background: ${props => props.theme.colors.primary};
    position: absolute;
    left: 0;
    right: 0;
    transition: transform 0.25s ease, background 0.35s ease;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  span {
    text-indent: -999px;
    overflow: hidden;
    height: 1px;
    background: ${props => props.theme.colors.primary};
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    /* transform: translateY(-50%); */
    opacity: 1;
    transition: opacity 0.25 ease;
  }

  @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width: 420px) {
    width: 35px;
    height: 16px;
    bottom: 35px;
  }

  ${props =>
    props.isActive &&
    css`
      &::before,
      &::after {
        background: #fff;
      }

      &::before {
        transform: translateY(12px) rotate(20deg);

        @media (max-width: 420px) {
          transform: translateY(7px) rotate(24deg);
        }
      }

      &::after {
        transform: translateY(-11px) rotate(160deg);

        @media (max-width: 420px) {
          transform: translateY(-8px) rotate(156deg);
        }
      }

      span {
        opacity: 0;
      }
    `}
`

const NavButton = ({ onClick, isActive }) => (
  <Button onClick={onClick} isActive={isActive}>
    <span>Menü</span>
  </Button>
)

export default NavButton
