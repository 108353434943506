/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { ParallaxProvider } from 'react-scroll-parallax'
import 'normalize.css'

import Header from './Header'
import Footer from './Footer'
import theme from '../theme'
import Overlay from './Overlay'

import 'slick-carousel/slick/slick.css'
import '../fonts/styles.css'

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Styrene A Web';
    font-weight:  300;
    font-size: 16px;
    line-height: 1.8;

    @media (max-width: 365px) {
      /* font-size: 14px; */
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.35s ease;
  }

  a:hover {
    color: ${props => props.theme.colors.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Styrene A Web';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    color: ${props => props.theme.colors.primary};
  }

  h1 {
    font-size: 30px;
    margin-top: 3rem;
    margin-bottom: 3.25rem;

    @media (max-width: 375px) {
      font-size: 24px;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 1.75rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 1.25rem;
  }
`

const Container = styled.div`
  overflow: hidden;
`

const Layout = ({ children, footer = {} }) => {
  const [overlay, setOverlay] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const onOverlayToggle = () => {
    setOverlay(!overlay)
  }

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <>
          <GlobalStyle />
          <Overlay isActive={overlay} />
          <Header
            siteTitle={data.site.siteMetadata.title}
            isOverlayActive={overlay}
            onOverlayToggle={onOverlayToggle}
          />
          <Container>
            <main>{children}</main>
            {!footer.hide && <Footer {...footer} />}
          </Container>
        </>
      </ParallaxProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
