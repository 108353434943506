import React from 'react'
import styled from 'styled-components'

import { useIntlPlugin } from '../../plugins/gatsby-plugin-intl'
import logoDe from '../images/oberhuber-jenal-logo-de.svg'
import logoEn from '../images/oberhuber-jenal-logo-en.svg'

const Img = styled.img`
  display: block;
  width: 222px;
  height: auto;
  max-width: 100%;

  @media (max-width: 375px) {
    width: 164px;
  }
`

const Logo = () => {
  const intlPlugin = useIntlPlugin()

  return (
    <>
      {intlPlugin.language === 'de' ? (
        <Img src={logoDe} alt="Oberhuber Jenal Rechtsanwälte" />
      ) : (
        <Img src={logoEn} alt="Oberhuber Jenal Attorneys at Law" />
      )}
    </>
  )
}

export default Logo
